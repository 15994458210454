<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 shadow p-4 mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 col-xl-6  ">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-2 text-right">Enrich :</div>
              <div class="col-6 col-xl-10  ">
                <select
                  class="form-control form-control-sm w-100"
                  v-model="data.enrich"
                >
                  <option value="">
                    เเสดงทั้งหมด
                  </option>
                  <option v-for="(e, i) in enrich" :key="i" :value="e.name">
                    {{ `${e.detail}(${e.name})` }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- ----------------------------- -->
          <div class="col-12 col-xl-6  ">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-3 text-right">Show On Web :</div>
              <div class="col-6 col-xl-9 ">
                <div class="row m-0 p-0">
                  <div
                    class="form-check form-check-inline "
                    v-for="(e, i) in showOnWeb"
                    :key="i"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="e.name"
                      :value="e.key"
                      v-model="data.showOnWeb"
                    />
                    <label class="form-check-label" :for="e.name">{{
                      e.name
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------- -->
          <div class="col-12 col-xl-6  ">
            <div class="row m-0 p-0">
              <div class="col-6">
                <div class="row form-inline mb-3">
                  <div class="col-6 text-right">Approval Date :</div>
                  <div class="col-6  ">
                    <input
                      :disabled="data.enrich !== 'A'"
                      class="form-control form-control-sm"
                      type="date"
                      v-model="data.apvstart"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row form-inline mb-3">
                  <div class="col-6 text-right">To :</div>
                  <div class="col-6  ">
                    <input
                      :disabled="data.enrich !== 'A' || data.apvstart === ''"
                      class="form-control form-control-sm"
                      type="date"
                      :min="data.apvstart"
                      v-model="data.apvend"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------- -->
          <div class="col-12 col-xl-6  ">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-2 text-right">On Shelf :</div>
              <div class="col-6 col-xl-10 ">
                <div class="row m-0 p-0">
                  <div
                    class="form-check form-check-inline "
                    v-for="(e, i) in web"
                    :key="i"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :id="e === '' ? 'ALL' : e"
                      :value="e"
                      v-model="data.web"
                    />
                    <label
                      class="form-check-label"
                      :for="e === '' ? 'ALL' : e"
                      >{{ e === "" ? "ALL" : e }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------- -->
          <div class="col-12  ">
            <div class="row form-inline mb-3 align-items-start">
              <div class="col-6 col-xl-1 text-right">SKU List :</div>
              <div class="col-6 col-xl-10  ">
                <textarea
                  class="form-control w-100"
                  rows="3"
                  @blur="setskuList"
                  v-model="data.skuList"
                ></textarea>
                <span class="font-0-8s text-muted"
                  ><span class="font-700">Exp : </span>sku1,sku2,sku3
                  <span class="text-danger font-700"
                    >*** Sku list max 100 sku ***</span
                  ><span></span
                ></span>
              </div>
            </div>
          </div>
          <!-- -------------------------- -->
          <div class="col-6">
            <button
              type="button"
              class="btn bt-main btn-sm mx-1 px-4"
              @click="confirm()"
            >
              <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
            </button>
            <button
              type="button"
              class="btn bt-main1 btn-sm mx-1 px-4"
              @click="redata()"
            >
              <span class="mx-1" v-html="$svg_icon.reset"></span>รีเซ็ต
            </button>
          </div>
          <!-- -------------------------- -->
          <div class="col-6 text-right">
            <button
              type="button"
              class="btn  btn-outline-dark btn-sm mx-1 px-4"
              @click="updatenow()"
            >
              <span class="mx-1" v-html="$svg_icon.Arrowclockwise"></span
              >อัพเดทตอนนี้
            </button>
            <p class="font-0-8s">
              ระบบอัตโนมัติดึงข้อมูลวันละครั้ง ดึงข้อมูลล่าสุดกด
              <span class="font-700">" อัพเดทตอนนี้ "</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 py-4 px-2">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ

            <span style="flex: 1; text-align: right; white-space: nowrap;"
              ><button class="btn btn-sm bt-bnb" @click="export_excel">
                นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
            ></span>
          </p>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              hover
              outlined
              show-empty
              responsive
              :busy="$store.getters['enrichproductActions/get_onload']"
              class="font-0-8s"
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(showOnWeb)="row">
                <p
                  class="badge badge-pill font-500 badge-twd m-0 py-1"
                  v-if="String(row.item.showOnWeb).trim() == 'PWB'"
                >
                  {{ row.item.showOnWeb }}
                </p>
                <!-- <p
                  class="badge badge-pill font-500 badge-bnb m-0 py-1"
                  v-else-if="String(row.item.showOnWeb).trim() == 'BNB'"
                >
                  {{ row.item.showOnWeb }}
                </p>
                <p
                  class="badge badge-pill font-500 badge-main m-0 py-1"
                  v-else-if="String(row.item.showOnWeb).trim() == 'BNB|TWD'"
                >
                  {{ row.item.showOnWeb }}
                </p> -->
                <p v-else>
                  {{ row.item.showOnWeb }}
                </p>
              </template>
              <template #cell(onSale)="row">
                <span
                  class="badge badge-pill font-500 badge-twd mx-1 py-1"
                  v-if="$serviceMain.chechnull(row.item.ETWD) !== ''"
                >
                  PWB
                </span>
                <!-- <span
                  class="badge badge-pill font-500 badge-bnb mx-1 py-1"
                  v-if="$serviceMain.chechnull(row.item.EBNB) !== ''"
                >
                  BNB
                </span> -->
              </template>
              <template #cell(enrich)="row">
                <!-- {{ row.item }} -->
                <span
                  v-if="
                    $serviceMain.chechnull(String(row.item.enrich).trim()) == ''
                  "
                >
                  <div
                    class="m-0 p-0 row w-100"
                    v-if="
                      $serviceMain.chechnull(
                        String(row.item.WrStatus).trim()
                      ) !== '' ||
                        $serviceMain.chechnull(
                          String(row.item.GpStatus).trim()
                        ) !== ''
                    "
                  >
                    <p class="m-0 p-0 w-100 ">
                      <span
                        class="badge font-500  "
                        :class="[
                          row.item.WrStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                        ]"
                        >CW</span
                      >
                      <span
                        class="badge font-500 "
                        :class="[
                          row.item.GpStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                        ]"
                        >GD</span
                      >
                    </p>
                  </div>
                </span>

                <span v-else>
                  <p class="badge   font-500 badge-main m-0 py-1  ">
                    {{ row.item.enrich }}
                  </p>
                </span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select
                  class="custom-select"
                  v-model="page_size"
                  @change="changesize($event)"
                >
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductOnWeb",
  data() {
    return {
      data: {
        enrich: "",
        skuList: "",
        showOnWeb: [1,0],
        web: "",
        apvstart: "",
        apvend: "",
      },
      enrich: [],
      showOnWeb: [
        { name: "No", key: 0 },
        { name: "Yes", key: 1 },
      ],
      web: ["", "PWB"],
      page_size: 100,
      page_size_list: [25, 50, 75, 100],
      page_num: 1,
      totalRows: 0,

      fields: [
        { key: "skCode", label: "skCode" },

        { key: "prNameTH", label: "prNameTH" },
        { key: "showOnWeb", label: "showOnWeb", class: "text-center" },

        {
          key: "prsstatus",
          label: "สถานะ SKU​",
          class: "text-center",
        },
        { key: "status", label: "status" },
        { key: "Storesize", label: "Storesize" },
        { key: "homexbrch", label: "homexbrch" },
        { key: "attrCode", label: "attrCode" },
        { key: "onSale", label: "On Shelf" },

        {
          key: "enrich",
          label: "enrich",
          class: "text-center",
          thStyle: { width: "8%" },
        },
        { key: "pim_apvdate120", label: "Approval Date" },
        { key: "AlertStatus", label: "หมายเหตุ" },
      ],
      items: [],
    };
  },
  watch: {
    "data.enrich": function() {
      this.data.apvstart = "";
      this.data.apvend = "";
    },
    "data.apvstart": function() {
      this.data.apvend = "";
    },
    page_num: function() {
      this.confirm();
    },
  },
  mounted() {
    this.getpimselectstatus();
  },
  methods: {
    async updatenow() {
      try {
        let mss = `ยืนยันการอัปเดต / Confirm Update?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `product/updadte_prouct_mkt`,
            { jdata: this.data },
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = { enrich: "", skuList: "", showOnWeb: [1] };
    },
    async export_excel() {
      try {
        if (this.items.length > 0) {
          this.data.excel = "Y";
          this.data.page_size = this.page_size;
          this.data.page_num = this.page_num;
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `product/getproduct_on_web`,
            { jdata: this.data },
            1
          );

          let name = `ProductOnWeb(${Date.now()}).xlsx`;
          this.$serviceMain.JsonToExcatMulSheet(getAPI.data, name);
        } else {
          throw `No Data`;
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    changesize(e) {
      this.page_size = e.target.value;
      this.confirm();
    },
    async confirm() {
      try {
        this.data.excel = "N";
        this.data.page_size = this.page_size;
        this.data.page_num = this.page_num;
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `product/getproduct_on_web`,
          { jdata: this.data },
          1
        );

        this.items = getAPI.data;

        this.totalRows = this.items.length == 0 ? 0 : this.items[0].total;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    setskuList() {
      try {
        let { skuList } = this.data;

        let arr = String(skuList).split(`\n`);

        if (arr[0].trim().length > 8 && arr[arr.length - 1].trim() === "") {
          arr = String(skuList)
            .replace(/\n/g, "")
            .split(","); // skuList.split(",");
        }
        if (arr.length > 100) {
          this.data.skuList = "";
          throw `Sku list max 100 sku / รายการ Sku สูงสุด 100 SKU`;
        }
        this.data.skuList = arr;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getpimselectstatus() {
      return new Promise(async (resolve, reject) => {
        try {
          let getAPI = await this.$serviceAPI.call_API(
            "get",
            `select/getpimselectstatus`,
            {},
            1
          );

          this.enrich = getAPI.data;
          resolve("OK");
        } catch (error) {
          this.$serviceMain.showErrorAlert(
            this,
            error.message == undefined ? error : error.message
          );
          this.enrich = [];
          reject(error);
        }
      });
    },
  },
};
</script>

<style></style>
